// start: base app object
export interface IProperty {
  id: number
  propertyName: string
  owningManagerId: number
}
// end: base app object

export type PropertySelectItem = {
  id: number
  propertyName: string
}

// start: provider types
export enum PropertyActions {
  SetAll = 'SET_ALL',
  SingleFilter = 'SINGLE_FILTER',
  Filter = 'FILTER',
  PropertyError = 'ERROR',
}

// end: provider types

export type PropertyGraphQLResponse = {
  id: number
  name: string
  owningManagerId: number
}
